import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './routes'
import store from './store';
import axios from 'axios';

// import moment and define as global using
import moment from 'moment';
import lodash from 'lodash';

Vue.prototype.moment = moment;
Vue.prototype.lodash = lodash;



// import lodash and define as global using
// import _ from 'lodash';
// Vue.prototype._ = _

Vue.filter('round', function(value, decimals) {
  if(!value) {
    value = 0;
  }

  if(!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});

Vue.filter('comma', function(value) {
  if(!value) {
    value = 0;
  }

  value = Number(value).toLocaleString();
  return value;
});


Vue.filter('datetime', function(value) {
  if(!value) {
    value = '';
  }

  value = moment(value).format('DD-MM-YYYY HH:mm:ss');
  return value;
});

Vue.filter('date', function(value) {
  if(!value) {
    value = '';
  }

  value = moment(value).format('DD-MM-YYYY');
  return value;
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL + '/v1/';
// console.log('ss config',process.env.VUE_APP_SS_API_URL);

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  // Do something with response error
  if (!error.response || error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expire');
      localStorage.removeItem('can');
      // auth.logout();
      router.push('/login');
  }
  return Promise.reject(error.response);
});

import Default from './layouts/DefaultLayout';
import Fullwidth from './layouts/FullwidthLayout';

Vue.component('default-layout',Default);
Vue.component('fullwidth-layout',Fullwidth);

Vue.config.productionTip = false;

var VM = new Vue({
  vuetify,
  store,
  router, 
  render: h => h(App)
}).$mount('#app');

